import React, { useEffect, useState, useRef } from "react";
import { Link, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { Scrollbar, Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import {
  LayoutSl,
  SEO2,
  CBreadCrumb,
  AssetImage,
  LWrap2,
  RecruitNews,
  Vertical,
  CImgCard,
  WordPic,
  WordFull,
  ContainerCrumbs,
  InterviewPic,
  WordPicWords,
  CInterviewJumbotron,
  CQuoteBox,
  CInterviewMedia,
  CSectTitle,
  CSchedule,
  CStars,
  CLabelCard,
  CBtnList,
} from "../../../../components/_index";
import "../../../../assets/_sass/page/recruit/common.scss";
import "../../../../assets/_sass/page/recruit/article01.scss";
import { title } from "process";
import { useMobile } from "../../../../utils/use-series";
const SubPage = ({ data }: { data: any }) => {
  const isSp = useMobile();
  const frontmatter = data?.markdownRemark?.frontmatter;

  const verticalData = {
    title: "米川野々香",
    content: "Nonoka Yonekawa",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
    exClass: "container_left_No1",
    exClassWord: "container_left_sp no-copy",
  };

  const verticalDataTime = {
    title: " １日のスケジュール",
    content: "",
    cla_left: "works_big_left",
    cla_right: "works_small_right",
  };
  const crumbsData = [
    {
      title: "Top",
      src: "/recruit/",
    },
    {
      title: "社員インタビュー",
      src: "/recruit/interview/",
    },
    {
      title: "米川野々香",
      src: "/recruit/interview/article02/",
      exClass:"no-copy",
    },
  ];
  const interviewFullData1 = {
    title: "入社を決めた理由をお聞かせください",
    paragraph: [
      {
        title:
          "決め手は「丸ノ内ホテル」の立地と規模です。東京駅からこれほど近いホテルは数少なく、ビジネスから観光まで多様な目的のお客さまをもてなせることが魅力でした。「世界のお客様へわが家のおもてなしを！ “真心感動ホテル”」というコンセプトからも、お客さまと密にコミュニケーションを取れるホテルという印象を抱きました。",
      },
      {
        title:
          "また、全205室とコンパクトであり、日々の接客に密なコミュニケーションで臨める点にも惹かれました。他の大型シティホテルは500室前後が一般的であり、業務もベルスタッフ、ドアスタッフ、フロントスタッフなど細分化されているケースが多いです。しかし当ホテルではそれらの全てをフロントスタッフが担当するため、お客さまとの関わりも自然と長く、距離も縮まると思いました。もちろん私の職場であるフレンチレストラン「ポム・ダダン」でも、お客さまを出迎えたスタッフは、座席への案内からドリンクや料理の提供、会計やお見送りまで担当します。",
      },
      {
        title:
          "そもそも私がホテル業界を目指したのは、学生時代にカフェでアルバイトをしたことがきっかけです。当時から接客スキルや笑顔を評価されていたので、自分の強みを生かし、ホスピタリティを極めたいと志望しました。",
      },
      {
        title:
          "初めて「丸ノ内ホテル」を訪れたのは就職活動の時ですね。説明会で「ロビーまでは誰でも入れる」と聞き、見学することにしました。当時は会員制のラウンジもあり「こんなに高級感のある場所に入ってもいいのだろうか」と緊張しましたが、足を踏み入れた途端、モダンジャパニズムを感じさせる内装に圧倒されました。「来て良かった」と感動したのを覚えています。スタッフも上質な振る舞いが美しく、学生の私にも親身になってくれたのが心地よかったです。",
      },
      {
        title:
          "また、せっかくの機会だからと「ポム・ダダン」に入り、スタッフの勧めで「伝統のアップルパイ」を頂きました。昭和初期から変わらぬレシピで、ナツメグとシナモンの風味が効いたリンゴと程よい甘さのカスタードクリームが絶妙なバランスでした。就活の緊張もほぐしてくれるので、当ホテルを見学する際にはぜひ味わってほしいですね。",
      },
    ],
  };
  const interviewFullData2 = {
    title: "仕事の醍醐味は何ですか",
    paragraph: [
      {
        title:
          "お客さまの期待を超えるサービスを提供できたと実感できた時ですね。小さなことかもしれませんが、例えばお連れ様の到着を待っている方のお水は、皆さまがそろったタイミングで新しい氷入りのグラスに交換しています。「ありがとう。気が利きますね」と言われるのは非常にうれしいですが、お客さまが次に何を望むか予測し、事前に適切なサービスを提供するのが私たちの仕事の醍醐味だと思っています。",
      },
      {
        title:
          "また、個室を利用するのは誕生会や結婚前の顔合わせ、ビジネスの会食など人生の大切な瞬間を迎えているお客さまがほとんどです。何も言われなくても、可能な限りホスト側の方と打ち合わせ、段取りを伺って大切な思い出のひとときになるようお手伝いしていますね。",
      },
      {
        title:
          "アシスタントマネージャーに昇進してからはスタッフのサポートにもやりがいを感じています。毎日のように名前を呼んで話しかけ、困っていることが無いか確認し、働きやすい環境づくりに努めています。「ありがとう」「助かりました」と言葉で伝えることも大切にしていますね。昇進にあたり、組織内のコミュニケーションを円滑にするための研修も受け、とても参考になりました。",
      },
    ],
  };
  const interviewFullData3 = {
    title: "職場の雰囲気はいかがでしょうか",
    paragraph: [
      {
        title:
          "一言で表すなら「親身」でしょう。誰もが「お客さまにもっと喜んでいただきたい」という思いで仕事に臨んでいるのが明確で、人の想いに寄り添うことを大切にするスタッフばかりです。",
      },
      {
        title:
          "この気持ちを具体化するために、レストラン部門では予約管理アプリを活用しています。お客さまに評価された点を記録して共有し、新たなサービスの開発につなげたりしていますね。料理の味や盛り付けを褒められた際には調理部門にもフィードバックするなど、チームワークや風通しの良さを感じています。",
      },
      {
        title:
          "さらに当ホテルは「三菱地所ホテルズ＆リゾーツ」に加わったこともあり、知識や技術の提供が強化されている印象です。特にアフタヌーンティーは、商品のデザイン性や繊細さが格段に向上したと思います。",
      },
      {
        title:
          "また、社内では役職を問わず互いを「さん」付けで呼んでいます。そのため上司にも話しかけやすく、新入社員や若手社員も気兼ねなくコミュニケーションが取れます。",
      },
      {
        title:
          "意見も言いやすく、私もよく「それは良いですね。明日から試してみましょう」と後輩のアイデアを取り入れています。もちろん私自身も職場環境やキャリアアップについて上司に相談しますね。定期的な面談も年に4回あり、成長を支えてくれています。",
      },
      {
        title:
          "さらに、マネージャーも頻繁に現場に顔を出し、気軽に話せる雰囲気を作り出しています。働きやすい職場づくりに向け、率先して動いてくれますね。",
      },
    ],
  };
  const interviewFullData4 = {
    title:
      "ホテルで働く魅力や、当社を目指す方々へのメッセージをお聞かせください",
    paragraph: [
      {
        title:
          "何といっても「ホテルでしか味わえない優雅なサービスや空間」を提供できることです。そしてお客さまの大切なひとときを演出し、喜びをともに感じられる点も大きな魅力でしょう。他には無い、私たちの業界ならではの特権と言えるかもしれません。",
      },
      {
        title:
          "一方で就職活動では「華やかに見える世界だが、裏側は泥臭い」と、どのホテルの説明会でも耳にしました。言葉通り、特に繁忙期になると気合いと根性が不可欠です。優れたサービスを提供するために、忙しさに負けない忍耐力も必要でしょう。私も調理部門のスタッフと話す際は、調理器具の音に負けないよう、お腹の底から声を出しています。",
      },
      {
        title:
          "「ポム・ダダン」ではクリスマスシーズンやアフタヌーンティーの時間帯が特にあわただしくなります。次々と注文が入る中、お客さまを待たせないよう迅速に対応しつつ、提供するサービスの質を保たなければなりません。料理の色や香り、食器の美しさなどお客さまの期待に見合った体験を提供できるよう細心の注意を払うのは大変なことです。",
      },
    ],
  };
  const picData1 = {
    img: {
      src: "/assets/images/recruit/interview/article02/interview02.png",
      alt: "",
    },
    title: "現在の業務内容と職場の雰囲気を教えてください",
    paragraph: [
      {
        title:
          "ホテル直営レストランの「ポム・ダダン」でアシスタントマネージャーを務めています。朝食、ランチ、喫茶、ディナーを提供するオールデイダイニングスタイルであり、私は朝食を担当することが多いですね。",
      },
      {
        title:
          "入社してまず感心したのは、お客さまのニーズを細やかにくみ取り、全社的に共有しようとする風土です。今も業務用アプリを使って各部門における日常の出来事や課題が共有され、望ましい対応について改善案も提示されます。",
      },
      {
        title:
          "さらにスタッフ憩室の横には「真心感動ボード」が設置され、お客さまに対してプロフェッショナルで気の利いたサービスを提供した人や、仲間を真摯にサポートした人が紹介されています。私も真心感動ボードの前を通るたびに眺め「こんな素敵なことがあったのか」と、自分の行動やサービスに生かしています。",
      },
    ],
    subcontent: "",
    // position: true,
    positionSp: true,
  };

  const picData2 = {
    img: {
      src: "/assets/images/recruit/interview/article02/interview02.png",
      alt: "",
    },
    title: "現在の業務内容や仕事の醍醐味を教えてください",
    paragraph: [
      {
        title:
          "フロントスタッフとして勤務し、時間帯責任者であるインチャージと呼ばれる立場にいます。総支配人や支配人の不在時に現場の全責任を負うポジションであり、任命された喜びと共にプレッシャーも感じています。",
      },
      {
        title:
          "実際に全490ある客室のチェックインやチェックアウトを行う中、難題が降りかかることもゼロではありません。万が一、サービスに不備があった場合はお客さまに直接会ってお詫びをし、名刺を渡して私なりの解決案を提示します。ご理解いただけるお客さまがほとんどで、中にはチェックアウト時に私を呼び、感謝の言葉やホテルへの賛辞を口にしてくださる方も多いです。まさにピンチはチャンスですよね。",
      },
      {
        title:
          "なおフロント業務では、特に予約や精算時にダブルブッキングや金額の誤入力が無いよう二重チェックを徹底しています。かつて私のミスが勤務時間外に発覚し、同僚が代わってお客さまに頭を下げてくれたことがあります。そんな、申し訳なく悔しい気持ちは誰にも味わってほしくありません。",
      },
      {
        title:
          "そんな思いもあり、特に後輩がお客さまに褒められている場面を見るのが醍醐味ですね。つい先日までは右往左往していたのに、同じような状況で人を喜ばせるまでに成長した姿を目の当たりにすると自分事のようにうれしくなります。",
      },
    ],
    subcontent: "",
    position: true,
  };
  const picData3 = {
    img: {
      src: "/assets/images/recruit/interview/article02/interview03.png",
      alt: "",
    },
    title: "",
    paragraph: [
      {
        title:
          "加えて、レストラン部門ならではの働く醍醐味は「お客さまの喜ぶ場面を共有できる」という点でしょう。私たちは例えば「お誕生日おめでとうございます」とお客さまと同じ空間でお祝いすることができます。この点はフロントスタッフと少し異なるかもしれません。",
      },
      {
        title:
          "ある時、予約の時点では分かりませんでしたが、振り袖を召されたお客さまが来店されました。時期的に成人式のお祝いだと分かり、厨房に相談して「ご成人おめでとうございます」のメッセージプレートを出したことがあります。サプライズに驚かれ、大喜びしてくれましたね。そんな体験ができるのもレストラン部門だからだと思います。",
      },
      {
        title:
          "さらにフロントスタッフが一人でお客さまに接するのに対し、「ポム・ダダン」ではスタッフ同士の連携を重視しています。お客さま全員に同じタイミングで料理を提供するにも、テーブルを片付けて速やかにセッティングするにも、アイコンタクトで互いの気持ちをくみ行動に移します。一糸乱れぬ動きが取れた時はとにかく快感ですね。",
      },
      {
        title:
          "私たちには見慣れた職場でも、お客さまにとっては特別な場所です。特に初めて「丸ノ内ホテル」を利用される方は目の輝きにドキドキやワクワクが表れています。そのお気持ちをより高められるよう、何ができるか考えることを大切にしています。もちろんリピーターの方にも、過去に喜んでいただいた場面などを思い浮かべながら接していますね。",
      },
      {
        title:
          "そんな努力の甲斐もあり、後輩が「真心感動ボード」に「米川さんの行動を参考にしたい」と書いてくれた時は大変うれしかったです。",
      },
    ],
    subcontent: "",
    position: true,
  };
  const picData4 = {
    img: {
      src: "/assets/images/recruit/interview/article02/interview04.png",
      alt: "",
    },
    title: "仕事を通じて成長した部分を教えてください",
    paragraph: [
      {
        title:
          "相手が何を求めているのか察する力が伸びたと思います。ひたすらお客さまを観察してきたからこそ強化されたわけですが、もちろん先輩方にも鍛えられました。",
      },
      {
        title:
          "経験が浅いうちは、お客さまに目を向けているつもりではいるものの「今はどんな料理を召し上がり、この後は何が来るのか」「お水やパンは足りているのか」「下げられる食器はあるのか」といった細かい情報まではなかなか気付くことが難しいものですし、かつての自分もそうでした。今、テーブルごと、あるいはレストラン全体で次に起こることを予測できるようになったのが大きな成長だと思います。",
      },
      {
        title:
          "これらの能力を生かし、将来はお客さまのニーズを形にする企画職や、スタッフの成長を支える人事職、広報職などにもチャレンジしたいですね。",
      },
    ],
    subcontent: "",
    // position: true,
    positionSp: true,
  };

  const picData5 = {
    img: {
      src: "/assets/images/recruit/interview/article02/interview05.png",
      alt: "",
    },
    title: "",
    paragraph: [
      {
        title:
          "それでも「おいしかった」「楽しかった」「また来ます」などと聞けば一瞬で疲れも吹き飛びます。お客さまのためにできることを自ら考え、行動ができる人は、大きなやりがいを得られるでしょう。",
      },
      {
        title:
          "ぜひ、当ホテルに新たな風を吹かせてくれる方々とお会いできるのを楽しみにしています。もちろん私も、新しく加わる仲間のアイデアが実現されるようサポートするつもりです。希望通りのキャリアが築けるよう後押ししたいですね。",
      },
      {
        title:
          "最後に、接客でも就職活動の面接でも、大事なのは笑顔です。プロになるために、鏡の前で練習することをお勧めします。私が当ホテルに採用された理由の一つは、「最初にあいさつをした時の笑顔がとても印象的だった」というものでした。どうぞ、参考にしてください。",
      },
    ],
    subcontent: "",
    position: true,
  };

  const carouselData = [
    {
      img: {
        src: "/assets/images/recruit/interview/interview.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 東京汐留 フロントチーム",
      content: (
        <>
          「人と関わる」を極めるためアニメ制作の道からホテルマンに軌道修正。
          <br />
          入社10年目を前に改めて「意思疎通や連携を大事にする会社」と実感
        </>
      ),

      end_title: "2015年 新卒入社",
      jumpUrl: "/recruit/interview/article01/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview2.png",
        alt: "",
      },
      title: "丸ノ内ホテル 料飲　(レストラン ポム・ダダン) ",
      content: (
        <>
          お客さまの大切な時間を共有できるのがレストラン部門の醍醐味。
          <br />
          価値ある取り組みを紹介する「真心感動ボード」も仕事への意欲に
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article02/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview3.png",
        alt: "",
      },
      title: "ザ ロイヤルパーク キャンバス 大阪北浜 フロントチーム",
      content: (
        <>
          「さまざまな接客を経験したい」と、これまで経験のないフロント業務ができる当社へ。ライフスタイルホテルの最高峰を目指し、若いスタッフの意見を採り入れてイベントを実施する
        </>
      ),

      end_title: "2019年 キャリア入社",
      jumpUrl: "/recruit/interview/article03/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview4.png",
        alt: "",
      },
      title: "ザ ロイヤルパークホテル アイコニック 大阪御堂筋 フロントチーム",
      content: (
        <>
          学生時代に宿泊したホテルのサービスに感動し、ホテル業界で働きたいと当社へ就職。お客さまやスタッフとのコミュニケーションを高め、おもてなしの最高峰を極める
        </>
      ),

      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article04/",
    },

    {
      img: {
        src: "/assets/images/recruit/interview/interview5.png",
        alt: "",
      },
      title:
        "ロイヤルパークホテル（東京・日本橋）調理部 洋食調理二課 バンケットコールド調理",
      content: (
        <>
          作る料理に一切の妥協をしない。
          <br />
          コンクールにも挑戦し、自分の武器を増やしながら成長を重ねる毎日
        </>
      ),
      end_title: "2020年 新卒入社",
      jumpUrl: "/recruit/interview/article05/",
    },
    {
      img: {
        src: "/assets/images/recruit/interview/interview6.png",
        alt: "",
      },
      title: "仙台ロイヤルパークホテル 婚礼",
      content: (
        <>
          「不採用でもあきらめない」
          <br />
          子どもの頃の憧れを形に掴んだキャリア
        </>
      ),

      end_title: "2020年 キャリア入社",
      jumpUrl: "/recruit/interview/article06/",
    },
  ];
  return (
    <LayoutSl isKv={false}>
      <SEO2
        title={frontmatter?.title}
        description={frontmatter?.description}
        isTop={false}
      />
      <section className="l_sect04  l_sect04_sp recruit-font">
        <LWrap2>
          <div className="new_main">
            <ContainerCrumbs data={crumbsData} />
            <div className="container_main container_main_interview">
              <Vertical data={verticalData} />
              <div className="container_right interview-container-right">
                <AssetImage
                  src="/assets/images/recruit/interview/article02/interview.png"
                  alt=""
                  loading="lazy"
                  wrapClassName="article-pc"
                />

                {isSp && (
                  <AssetImage
                    src="/assets/images/recruit/interview/article02/interview-article02-media.png"
                    alt=""
                    loading="lazy"
                    wrapClassName="article-media"
                  />
                )}

                <div className="interview_title">
                  <p className="m_title_message  m_title_message-media">
                    お客さまの大切な時間を共有できるの
                    <br className="u_sp" />
                    がレストラン部門の醍醐味。
                    <br />
                    価値ある取り組みを紹介する「真心感
                    <br className="u_sp" />
                    動ボード」も仕事への意欲に
                    <br />
                  </p>
                </div>
                <div className="interview_subtitle">
                  <p className="m_subtitle_message  m_title_subtitle-media">
                    2020年 新卒入社
                    <br />
                    丸ノ内ホテル 料飲　(レストラン ポム・ダダン)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04 l_sect04_sp recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main">
              <WordFull
                data={interviewFullData1}
                exClass=" wordfull-article-media container_main_message_full2"
              />
              <WordPic
                data={picData1}
                exClass=" container_main_message_bisect2"
                exRightClass="article02-p1-p"
              />
              <WordFull
                data={interviewFullData2}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData3}
                exClass="container_main_message_bisect2"
                exRightClass="article02-p3-p"
              />
              <WordFull
                data={interviewFullData3}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData4}
                exClass="reverse-container_main_message_bisect  container_main_message_bisect2"
                exRightClass="small-right-pic bisect_right2"
              />
              <WordFull
                data={interviewFullData4}
                exClass="container_main_message_full2"
              />
              <WordPic
                data={picData5}
                exClass=" container_main_message_bisect2"
                exRightClass="article02-media-final-right"
              />
            </div>
          </div>
        </LWrap2>
      </section>
      <section className="l_sect04  u_grey recruit-font">
        <LWrap2>
          <div className="person-article-div">
            <div className="new_main u_grey">
              <div className="container_main container_main_time container_main_time-media">
                <Vertical data={verticalDataTime} />
                <div className="demo2 article01-mythBox">
                  <div className="timeboxItem01">
                    <div className="mythBox">
                      <div className="timebox-left">
                        <p className="timebox-title"></p>
                        <div className="timeline-item">
                          <i className="timeline-axis bg1"></i>
                          <div className="timeline-content text timeline-content-media">
                            <div className="timeline-detail">
                              {" "}
                              <span className="time_span_title_left">
                                11:00
                              </span>{" "}
                              <span className="time_span_title_right fs-16-30 ls-40 article-pc">
                                出勤
                              </span>{" "}
                            </div>
                            <div className="timeline-title02 fs-16-30 ls-40 timeline-title-media">
                              <div className="article-timeline-media">出勤</div>
                              予約確認、ランチタイム準備
                            </div>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <i className="timeline-axis bg2"></i>
                          <div className="timeline-content text timeline-content-media">
                            <div className="timeline-detail">
                              {" "}
                              <span className="time_span_title_left">
                                11:30
                              </span>{" "}
                              <span className="time_span_title_right fs-16-30 ls-40  article-pc">
                                ランチ営業
                              </span>{" "}
                            </div>
                            <div className="timeline-title02 fs-16-30 ls-40 timeline-title-media">
                              <div className="article-timeline-media">
                                ランチ営業
                              </div>
                              オーダーテイク、料理提供、バッシング、会計
                            </div>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <i className="timeline-axis bg3"></i>
                          <div className="timeline-content text timeline-content-media">
                            <div className="timeline-detail">
                              {" "}
                              <span className="time_span_title_left">
                                14:00
                              </span>{" "}
                              <span className="time_span_title_right fs-16-30 ls-40  article-pc">
                                カフェタイム営業、アフタヌーンティー提供
                              </span>{" "}
                            </div>
                            <div className="timeline-title02 timeline-title-media">
                              <div className="article-timeline-media">
                                カフェタイム営業、アフタヌーンティー提供
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <i className="timeline-axis bg4"></i>
                          <div className="timeline-content text timeline-content-media">
                            <div className="timeline-detail">
                              {" "}
                              <span className="time_span_title_left">
                                15:00
                              </span>{" "}
                              <span className="time_span_title_right fs-16-30 ls-40 article-pc">
                                休憩
                              </span>{" "}
                            </div>
                            <div className="timeline-title02 timeline-title-media">
                              <div className="article-timeline-media">休憩</div>
                            </div>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <i className="timeline-axis bg5"></i>
                          <div className="timeline-content text timeline-content-media">
                            <div className="timeline-detail">
                              {" "}
                              <span className="time_span_title_left">
                                16:00
                              </span>{" "}
                              <span className="time_span_title_right fs-16-30 ls-40 article-pc">
                                予約確認、ディナータイム準備、ブリーフィング
                              </span>{" "}
                            </div>
                            <div className="timeline-title02 timeline-title-media">
                              <div className="article-timeline-media">
                                予約確認、ディナータイム準備、ブリーフィング
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <i className="timeline-axis bg6"></i>
                          <div className="timeline-content text timeline-content-media">
                            <div className="timeline-detail">
                              {" "}
                              <span className="time_span_title_left">
                                17:00
                              </span>{" "}
                              <span className="time_span_title_right fs-16-30 ls-40 article-pc">
                                ディナータイム営業
                              </span>{" "}
                            </div>
                            <div className="timeline-title02 fs-16-30 ls-40 timeline-title-media">
                              <div className="article-timeline-media">
                                ディナータイム営業
                                <br className="u_sp" />
                                オーダーテイク、料理提供、バッシング、会計
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="timeline-item">
                          <i className="timeline-axis bg7"></i>
                          <div className="timeline-content text timeline-content-media">
                            <div className="timeline-detail">
                              {" "}
                              <span className="time_span_title_left">
                                19:30
                              </span>{" "}
                              <span className="time_span_title_right fs-16-30 ls-40 article-pc">
                                退勤
                              </span>{" "}
                            </div>
                            <div className="timeline-title02 timeline-title-media">
                              <div className="article-timeline-media">退勤</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="timeline-02-text fs-16-30 ls-40 fs-14-28-sp">
                      営業時間が6：30∼22：00となるため、早番シフト,中番シフト、遅番シフトにわけて勤務しています
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LWrap2>
      </section>

      <section className="l_sect04 recruit-font">
        <LWrap2>
          <div className="swiper swiper_buttons swiper_buttons_media">
            <div className="swiper-button-prev turnPrev"></div>&nbsp;
            <div className="swiper-button-next turnNext"></div>
          </div>
          <div className="new_main">
            <div className="container_main_carouse">
              {isSp ? (
                <>
                  <div className="carouse_right article-media article-swiper-media">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={1}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              ) : (
                <>
                  <div className="carouse_right article-pc">
                    <Swiper
                      autoplay={false}
                      loop={false}
                      modules={[Scrollbar, Autoplay, Navigation]}
                      speed={1000}
                      className="c_labelCardSlider"
                      slidesPerView={3.5}
                      centeredSlides={false}
                      breakpoints={{
                        "768": {
                          spaceBetween: 0,
                        },
                        "769": {
                          spaceBetween: 50,
                        },
                      }}
                      spaceBetween={50}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                      }}
                    >
                      {carouselData.map((item, index) => {
                        return (
                          <SwiperSlide>
                            <div className="carouse_body">
                              <Link to={item.jumpUrl}>
                                <AssetImage
                                  src={`${item.img.src}`}
                                  alt=""
                                  loading="lazy"
                                />
                                <div className="carouse_title">
                                  {item.title}
                                </div>
                                <div className="carouse_content">
                                  <p>{item.content}</p>
                                </div>
                                <div className="carouse_end">
                                  {item.end_title}
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="return_overview_class">
            {isSp ? (
              <>
                <Link to="/recruit/interview/" target="_blank">
                  <div className="return_overview_class_internal return-overview-media">
                    <div className="btn-list">一覧に戻る</div>
                    <div className="circle-icon">
                      <img
                        src="/assets/images/recruit/common/icon-new-right.png"
                        alt=""
                      />
                    </div>
                  </div>
                </Link>
              </>
            ) : (
              <>
                <div className="return_overview_class_internal return-overview-media hover_active">
                  一覧に戻る
                  <a href="/recruit/interview/">
                    <img
                      src="/assets/images/recruit/goto.svg"
                      className="return_overview_images"
                    />
                  </a>
                </div>
              </>
            )}
          </div>
        </LWrap2>
      </section>
    </LayoutSl>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
